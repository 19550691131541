export const languagesObject = {
    'aa': 'Afar',
    'ab': 'Abkhazian',
    'ae': 'Avestan',
    'af': 'Afrikaans',
    'ak': 'Akan',
    'akk': 'Akkadian',
    'am': 'Amharic',
    'an': 'Aragonese',
    'ar': 'Arabic',
    'arc': 'Aramaic',
    'arz': 'Egyptian Arabic',
    'as': 'Assamese',
    'ase': 'American Sign Language',
    'av': 'Avaric',
    'ay': 'Aymara',
    'az': 'Azerbaijani',
    'azb': 'South Azerbaijani',
    'ba': 'Bashkir',
    'be': 'Belarusian',
    'bg': 'Bulgarian',
    'bgc': 'Haryanvi',
    'bh': 'Bihari',
    'bho': 'Bhojpuri',
    'bi': 'Bislama',
    'bm': 'Bambara',
    'bn': 'Bengali',
    'bo': 'Tibetan',
    'br': 'Breton',
    'brx': 'Bodo',
    'bs': 'Bosnian',
    'ca': 'Catalan; Valencian',
    'ce': 'Chechen',
    'ceb': 'Cebuano',
    'ch': 'Chamorro',
    'cho': 'Choctaw',
    'chr': 'Cherokee',
    'ckb': 'Central Kurdish',
    'co': 'Corsican',
    'cop': 'Coptic',
    'cr': 'Cree',
    'cs': 'Czech',
    'cu': 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
    'cv': 'Chuvash',
    'cy': 'Welsh',
    'da': 'Danish',
    'de': 'German',
    'doi': 'Dogri',
    'dv': 'Divehi; Dhivehi; Maldivian',
    'dz': 'Dzongkha',
    'ee': 'Ewe',
    'el': 'Greek (Modern 1453-)',
    'en': 'English',
    'eo': 'Esperanto',
    'es': 'Spanish',
    'et': 'Estonian',
    'eu': 'Basque',
    'fa': 'Persian',
    'ff': 'Fulah',
    'fi': 'Finnish',
    'fil': 'Filipino',
    'fj': 'Fijian',
    'fo': 'Faroese',
    'fr': 'French',
    'fy': 'Western Frisian',
    'ga': 'Irish',
    'gd': 'Scottish Gaelic',
    'gl': 'Galician',
    'gn': 'Guarani',
    'gu': 'Gujarati',
    'guz': 'Gusii',
    'gv': 'Manx',
    'ha': 'Hausa',
    'hak': 'Hakka Chinese',
    'haw': 'Hawaiian',
    'he': 'Hebrew',
    'hi': 'Hindi',
    'hmn': 'Hmong',
    'ho': 'Hiri Motu',
    'hr': 'Croatian',
    'ht': 'Haitian Creole',
    'hu': 'Hungarian',
    'hy': 'Armenian',
    'hz': 'Herero',
    'ia': 'Interlingua',
    'id': 'Indonesian',
    'ie': 'Interlingue',
    'ig': 'Igbo',
    'ii': 'Sichuan Yi; Nuosu',
    'ik': 'Inupiaq',
    'ilo': 'Iloko',
    'io': 'Ido',
    'is': 'Icelandic',
    'it': 'Italian',
    'iu': 'Inuktitut',
    'iw': 'Hebrew',
    'ja': 'Japanese',
    'jv': 'Javanese',
    'ka': 'Georgian',
    'kam': 'Kamba',
    'kg': 'Kongo',
    'ki': 'Kikuyu',
    'kj': 'Kuanyama; Kwanyama',
    'kk': 'Kazakh',
    'kl': 'Kalaallisut',
    'kln': 'Kalenjin',
    'km': 'Khmer',
    'kn': 'Kannada',
    'ko': 'Korean',
    'kok': 'Konkani',
    'kr': 'Kanuri',
    'ks': 'Kashmiri',
    'ku': 'Kurdish',
    'kv': 'Komi',
    'kw': 'Cornish',
    'ky': 'Kyrgyz',
    'la': 'Latin',
    'lad': 'Ladino',
    'lb': 'Luxembourgish',
    'lg': 'Ganda',
    'li': 'Limburgan; Limburger; Limburgish',
    'ln': 'Lingala',
    'lo': 'Lao',
    'lt': 'Lithuanian',
    'lu': 'Luba-Katanga',
    'luo': 'Luo',
    'lus': 'Mizo',
    'luy': 'Luyia',
    'lv': 'Latvian',
    'mai': 'Maithili',
    'mas': 'Masai',
    'mer': 'Meru',
    'mg': 'Malagasy',
    'mh': 'Marshallese',
    'mi': 'Maori',
    'mk': 'Macedonian',
    'ml': 'Malayalam',
    'mn': 'Mongolian',
    'mni': 'Manipuri',
    'mo': 'Moldavian',
    'mr': 'Marathi',
    'ms': 'Malay',
    'mt': 'Maltese',
    'mxp': 'Mixe',
    'my': 'Burmese',
    'mzn': 'Mazanderani',
    'na': 'Nauru',
    'nah': 'Nahuatl',
    'nan': 'Min Nan Chinese',
    'nb': 'Bokmål (Norwegian); Norwegian Bokmål',
    'nd': 'North Ndebele',
    'ne': 'Nepali',
    'ng': 'Ndonga',
    'nl': 'Dutch',
    'nn': 'Norwegian Nynorsk; Nynorsk (Norwegian)',
    'no': 'Norwegian',
    'nr': 'South Ndebele',
    'nso': 'Northern Sotho',
    'nv': 'Navajo',
    'ny': 'Chichewa; Chewa; Nyanja',
    'oc': 'Occitan',
    'oj': 'Ojibwa',
    'om': 'Oromo',
    'or': 'Oriya',
    'os': 'Ossetian; Ossetic',
    'pa': 'Panjabi; Punjabi',
    'pap': 'Papiamento',
    'pcm': 'Nigerian Pidgin',
    'pi': 'Pali',
    'pl': 'Polish',
    'pnb': 'Western Panjabi',
    'ps': 'Pashto',
    'pt': 'Portuguese',
    'qu': 'Quechua',
    'rm': 'Romansh',
    'rn': 'Rundi',
    'ro': 'Romanian',
    'ru': 'Russian',
    'rw': 'Kinyarwanda',
    'sa': 'Sanskrit',
    'sah': 'Yakut',
    'sat': 'Santali',
    'sc': 'Sardinian',
    'sco': 'Scots',
    'scn': 'Sicilian',
    'sd': 'Sindhi',
    'sdp': 'Sherdukpen',
    'se': 'Northern Sami',
    'sg': 'Sango',
    'sh': 'Serbo-Croatian',
    'si': 'Sinhala',
    'sk': 'Slovak',
    'sl': 'Slovenian',
    'sm': 'Samoan',
    'sn': 'Shona',
    'so': 'Somali',
    'sq': 'Albanian',
    'sr': 'Serbian',
    'ss': 'Swati',
    'st': 'Southern Sotho',
    'su': 'Sundanese',
    'sv': 'Swedish',
    'sw': 'Swahili',
    'ta': 'Tamil',
    'te': 'Telugu',
    'tg': 'Tajik',
    'th': 'Thai',
    'ti': 'Tigrinya',
    'tk': 'Turkmen',
    'tl': 'Tagalog',
    'tlh': 'Klingon',
    'tn': 'Tswana',
    'to': 'Tongan',
    'tok': 'Toki Pona',
    'tpi': 'Tok Pisin',
    'tr': 'Turkish',
    'ts': 'Tsonga',
    'tt': 'Tatar',
    'tw': 'Twi',
    'ty': 'Tahitian',
    'ug': 'Uyghur',
    'uk': 'Ukrainian',
    'und': 'Undefined',
    'ur': 'Urdu',
    'uz': 'Uzbek',
    've': 'Venda',
    'vi': 'Vietnamese',
    'vo': 'Volapük',
    'vro': 'Võro',
    'wa': 'Walloon',
    'wal': 'Wolaytta',
    'war': 'Waray',
    'wo': 'Wolof',
    'wuu': 'Wu Chinese',
    'xh': 'Xhosa',
    'yi': 'Yiddish',
    'yo': 'Yoruba',
    'yue': 'Cantonese',
    'za': 'Zhuang; Chuang',
    'zh': 'Chinese',
    'zh-yue': 'Chinese - Cantonese',
    'zh-cmn': 'Chinese - Mandarin',
    'zu': 'Zulu',
    'zxx': 'No Language',
 // Regional Variants
    'bn-BD': 'Bengali (Bangladesh)',
    'bn-IN': 'Bengali (India)',
    'de-AT': 'German (Austria)',
    'de-CH': 'German (Switzerland)',
    'de-DE': 'German (Germany)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'en-GB': 'English (United Kingdom)',
    'en-IE': 'English (Ireland)',
    'en-IN': 'English (India)',
    'en-US': 'English (United States)',
    'es-419': 'Spanish (Latin America)',
    'es-ES': 'Spanish (Spain)',
    'es-MX': 'Spanish (Mexico)',
    'es-US': 'Spanish (United States)',
    'fa-AF': 'Persian (Afghanistan)',
    'fa-IR': 'Persian (Iran)',
    'fr-BE': 'French (Belgium)',
    'fr-CA': 'French (Canada)',
    'fr-CH': 'French (Switzerland)',
    'fr-FR': 'French (France)',
    'hak-TW': 'Hakka (Taiwan)',
    'hi-Latn': 'Hindi (Latin)',
    'ja-JP': 'Japanese (Japan)',
    'ml-IN': 'Malayalam (India)',
    'mn-Mong': 'Mongolian (Mongolia)',
    'ms-SG': 'Malaysian (Singapore)',
    'nan-TW': 'Min Nan Chinese (Taiwan)',
    'nl-BE': 'Dutch (Belgium)',
    'nl-NL': 'Dutch (Netherlands)',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    'ro-MD': 'Romanian (Moldova)',
    'ru-Latn': 'Russian (Latin)',
    'sr-Cyrl': 'Serbian (Cyrillic)',
    'sr-Latn': 'Serbian (Latin)',
    'yue-HK': 'Cantonese (Hong Kong)',
    'zh-CN': 'Chinese (China)',
    'zh-HK': 'Chinese (Hong Kong)',
    'zh-Hans': 'Chinese (Simplified)',
    'zh-Hant': 'Chinese (Traditional)',
    'zh-SG': 'Chinese (Singapore)',
    'zh-TW': 'Chinese (Taiwan)'
};

export default languagesObject;
