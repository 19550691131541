import { hot } from 'react-hot-loader';
import Pleasure from './theme/pleasure.js';

require('./components/admin/styles.less');
require('./components/audit_queue/styles.less');
require('./components/auth/styles.less');
require('./components/aw_accounts/styles.less');
require('./components/channels/styles.less');
require('./components/common/styles.less');
require('./components/common/utils.less');
require('./components/domain_manager/styles.less');
require('./components/filters/styles.less');
require('./components/home/styles.less');
require('./components/markup/charts/charts.less');
require('./components/markup/styles.less');
require('./components/menu.less');
require('./components/pacing_report/styles.less');
require('./components/forecasting/styles.less');
require('./components/research/styles.less');
require('./components/review/styles.less');
require('./components/inspect/styles.less');
require('./components/precision_targeting/styles.less');
require('./components/segments/styles.less');
require('./components/vetting_tool/styles.less');
require('./components/videos/styles.less');
require('./components/reporting/styles.less');
require('./components/my_custom_data/styles.less');
require('./components/exports_manager/styles.less');
require('./components/comscore_editor/styles.less');
require('./components/tags_editor/styles.less');
require('./components/tagger/styles.less');
require('./components/notifications_manager/styles.less');
require('./components/data_tracker/styles.less');
require('./components/explore/styles.less');
require('./components/markup/selectors/styles.less');
require('react-phone-number-input/style.css');

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router';
import $ from 'jquery';
import _ from 'lodash';
import Favicon from 'react-favicon';
import UserManager from './core/UserManager';
import history from './history';
import RouterHelper from './core/RouterHelper';
import PubSub from 'pubsub-js';
import GlobalConst from 'utils/GlobalConst';
import Button from 'components/markup/Button';
import Error from './components/Error';
import SideMainMenu from './components/base/SideMainMenu';
import AnalyticsManager from './core/AnalyticsManager';
import { Modal, ModalFooter, ModalHeader } from './components/markup/Modal';
import ContactUsModal from './components/auth/ContactUsModal';
import ReactTooltip from 'react-tooltip';
import GDPRCookiesBox from './components/auth/GDPRCookiesBox.js';
import AuthDataProvider from './components/auth/AuthDataProvider';
import NumberInputModal from './components/auth/NumberInputModal';
import WhiteLabelController from './core/WhiteLabelController';
import ExportsManagerDataProvider from './components/exports_manager/ExportsManagerDataProvider';
import MainMenu from './components/base/MainMenu.js';

const componentsCache = {};

class AsyncComponent extends PureComponent {

	static propTypes = {
		getComponent: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {Component: componentsCache[props.match.path]};
	}

	componentDidMount() {
		this.resolveComponent(this.props);
	}

	componentDidUpdate(prevProps){
		if (prevProps.match.path !== this.props.match.path) {
			this.setState({Component: null});
			this.resolveComponent(this.props);
		}
	}

	resolveComponent(props) {
		const path = props.match.path;
		const cached = componentsCache[path];
		if(!cached) {
			props.getComponent().then(module => {
				const component = module.default || module;
				componentsCache[path] = component;
				this.setState({Component: component});
			})
		} else {
			this.setState({Component: cached});
		}
	}

	render() {
		const { Component } = this.state;
		if (Component) {
			return <Component {...this.props}/>;
		}
		return null;
	}

}

export const AutoRedirectRoute = ({ component: Component, getComponent, checkRedirect, extraProps, ...rest }) => (
	<Route {...rest} render={props => {
		if(checkRedirect) {
			const redirectTo = checkRedirect();
			if(!_.isEmpty(redirectTo)) {
				return <Redirect to={redirectTo}/>;
			}
		}
		const resultProps = _.isEmpty(extraProps) ? props : {...props, ...extraProps};
		return Component ? <Component {...resultProps}/> : <AsyncComponent {...resultProps} getComponent={getComponent}/>;
	}}/>
);

class Routes extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			modalMessage: null,
			didAutoLogin: false,
			showGDPRBox: true,
			siteAssets: null,
			loadingUserData: true,
			googleAdsUserData: {},
			dv360UserData: {},
			oauthRequired: true,
			showContactUs: false,
			exportsStatus: {}
		};
		PubSub.subscribe(GlobalConst.SHOW_MODAL_MSG, (type, modalMessage) => {
			if(modalMessage && modalMessage.type === GlobalConst.MODAL_TYPE_CONTACT_US) {
				this.setState({ showContactUs: true }, () => $('#app-contact-us-modal').modal('show'));
				return;
			}
			this.setState({ modalMessage }, () => {
				$(`#app-modal-${modalMessage.type}`).modal('show');
			})
		});
		PubSub.subscribe('FETCH_OAUTH_DATA', () => this.fetchUserData());
		PubSub.subscribe('CLEAR_OAUTH', () => this.clearOAuthData());
		PubSub.subscribe('CLEAR_GADS_OAUTH', () => this.clearSingleOAuth(0));
		PubSub.subscribe('CLEAR_DV360_OAUTH', () => this.clearSingleOAuth(1));
		PubSub.subscribe('TRIGGER_NOTIFICATIONS', () => {
			this.onFetchExportsStatus();
			this.exportsInterval = setInterval(() => this.onFetchExportsStatus(), 30000);
		});
		PubSub.subscribe('CLEAR_TRIGGER_NOTIFICATIONS', () => clearInterval(this.exportsInterval));
		$(document).on('click', '.dropdown-menu.noclose > li', e => e.stopPropagation());
		$(document).on('click', '.dropdown-menu > li > .noclose', e => e.stopPropagation());
	}

	componentDidMount() {
		Pleasure.init();
		WhiteLabelController.getLocalAssets().then(data => this.setState({ siteAssets: data }));
		AnalyticsManager.sendPageViewAnalytics();
		this.routesUserLogin();
	}

	routesUserLogin = () => {
		let magicToken = false;
		let currentQuery = RouterHelper.currentQuery();
		if (currentQuery.emailLogin) {
			magicToken = currentQuery.magicLink;
		}
		UserManager.autoLogin(magicToken)
			.catch(xhrError => {
				if (xhrError?.responseStatus === 400) {
					PubSub.publish(GlobalConst.SHOW_MODAL_MSG, {type: 3, message: xhrError.json.message});
				}
			})
			.finally(() => {
			RouterHelper.setTitle();
			this.setState({didAutoLogin: true}, () => {
				if (location.pathname === '/' && UserManager.isAuthorized) return RouterHelper.goToDefaultRoute();
				else if (location.pathname !== '/' && UserManager.isAuthorized && !UserManager.user.isActive && UserManager.user.email) return RouterHelper.goToWelcomePage();
				else if (location.pathname !== '/' && location.pathname !== '/password_reset/' && !UserManager.isAuthorized) return RouterHelper.goToLogin();
				else if (location.pathname !== '/' && location.pathname !== '/password_reset/' && !UserManager.user.email) return RouterHelper.goToErrorPage();
			});
		});
	}

	clearSingleOAuth = type => {
		if (type) return this.setState({ dv360UserData: {} });
		return this.setState({ googleAdsUserData: {} });
	}

	fetchUserData = () => {
        this.setState({ loadingUserData: true });
        return AuthDataProvider.fetchOAuthUserData()
            .then(data => {
                this.setState({ googleAdsUserData: data.gads, dv360UserData: data.dv360, oauthRequired: !data.bypass_oauth });
                RouterHelper.removeFromQuery('oauth_code');
            })
            .catch(() => PubSub.publish(GlobalConst.SHOW_MODAL_MSG, {type: 3, message: {readableMessage: 'Error getting user oauth data'}}))
            .finally(() => this.setState({ loadingUserData: false }));
    }

	clearOAuthData = () => {
		this.setState({ googleAdsUserData: {}, dv360UserData: {}, loadingUserData: false });
	}

	onFetchExportsStatus = () => {
		ExportsManagerDataProvider.getExportsNotifications()
			.then(status => this.setState({ exportsStatus: status }));
	}

	checkUserAdmin = () => {
		if (!UserManager.user.isAdminUser) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkResearchPermission = type => {
		const { canAccessResearch, canAccessResearchDetail } = UserManager.user;
		if (!canAccessResearch) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type === 'insights_single' && !canAccessResearchDetail) {
			return RouterHelper.accessDeniedPageURL();
		}
	};

	checkBuildPermission = () => {
		if (!UserManager.user.canAccessBuild) {
			return RouterHelper.accessDeniedPageURL();
		}
	};

	checkResearchVettingToolPermission = () => {
		if (!UserManager.user.canAccessResearchVetting&&!UserManager.user.canAccessResearchVettingBasic) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkCTLVettingToolPermission = () => {
		if (!UserManager.user.canAccessCTLVet&&!UserManager.user.canAccessCTLVetBasic) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkReviewPermissions = () => {
		if (!UserManager.user.canAccessReview) {
			return RouterHelper.accessDeniedPageURL();
		}
	};

	checkBSTEPermissions = () => {
		if (!UserManager.user.canAccessBSTE) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkBlocklistPermissions = () => {
		if (!UserManager.user.canAccessBlocklist) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkCIDManagerPermissions = () => {
		if (!UserManager.user.canAccessCIDManager) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkCPRScriptPermissions = () => {
		if (!UserManager.user.canAccessCPRScript) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkUserAnalyticsPermissions = () => {
		if (!UserManager.user.canAccessUserAnalytics) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkUserManagerPermissions = () => {
		if (!UserManager.user.canAccessUserManager) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkComscoreEditorPermissions = () => {
		if (!UserManager.user.canAccessComscoreEditor) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkUmbrellaTopicsPermissions = () => {
		if (!UserManager.user.canAccessUmbrellaTopics) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

    checkExportsManagerPermissions = () => {
        if (!UserManager.user.domainAccessToExportsManager) {
            return RouterHelper.accessDeniedPageURL();
        }
    }

	checkForecastingPermissions = () => {
		if (!UserManager.user.canAccessForecasting) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkNotificationsManagerPermissions = () => {
		if (!UserManager.user.canAccessNotificationsManager) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkPacingReportPermissions = () => {
		if (!UserManager.user.canAccessPacingReport) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkReportingPermissions = type => {
		if (!UserManager.user.canAccessReporting&&!UserManager.user.canAccessReportingAccounts&&!UserManager.user.canAccessReportingOpportunities) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type == 'ac_reporting' && !UserManager.user.canAccessReportingAccounts) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type == 'op_reporting' && !UserManager.user.canAccessReportingOpportunities) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type == 'exports' && !UserManager.user.canAccessReportingExports) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type == 'exports_opportunity' && !UserManager.user.canAccessReportingOpportunityExports) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkToolsPermissions = type => {
		if (!UserManager.user.canAccessTools) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type === 'audit' && !UserManager.user.canAccessAuditQueue) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type === 'custom_data' && !UserManager.user.canAccessMyCustomData) {
			return RouterHelper.accessDeniedPageURL();
		} else if (type === 'custom_tagger' && !UserManager.user.canAccessCustomTagger) {
			return RouterHelper.accessDeniedPageURL();
		}
	};

	checkDomainManagerPermissions = () => {
		if (!UserManager.user.canAccessDomainManager) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkDataTrackerPermissions = () => {
		if (!UserManager.user.canAccessDataTracker) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	checkInspectPermissions = () => {
		if (!UserManager.user.canAccessInspect) {
			return RouterHelper.accessDeniedPageURL();
		}
	}

	hideModal(callback){
		this.setState({modalMessage: null}, callback);
	}

	onGDPRCookiesAction = response => {
		AuthDataProvider.onGDPRCookiesAction(response);
		this.setState({ showGDPRBox: false });
	}

	renderModal() {
		const { modalMessage } = this.state;
		if(!modalMessage) {
			return null;
		}
		const { type, message, title, buttons, extraProps={} } = modalMessage;
		let modalTitle, modalLabel;
		let payload = message;
		switch (type) {
			case GlobalConst.MODAL_TYPE_SUCCESS:
				modalLabel = extraProps.label || '';
				modalTitle = title || 'Success';
				break;
			case GlobalConst.MODAL_TYPE_WARN:
				modalLabel = extraProps.label || '';
				modalTitle = title || 'Warning';
				break;
			case GlobalConst.MODAL_TYPE_ERROR:
				modalTitle = extraProps.label ? title || 'Error' : title || '';
				modalLabel = extraProps.label || 'Error';
				payload = <Error error={message}/>;
				break;
			default:
				modalLabel = extraProps.label || '';
				modalTitle = title || 'Info';
				break;
		}
		return (
			<Modal id={`app-modal-${type}`} className='common-app-modal' {...extraProps}>
				{(!_.isEmpty(modalTitle) || !_.isEmpty(modalLabel)) && <ModalHeader>
					{!_.isEmpty(modalLabel) && <span className='modal-label'>{modalLabel}</span>}
					{modalTitle}
					<span className='material-icons close-common-modal' data-dismiss='modal'>close</span>
				</ModalHeader>}
				{payload}
				<ModalFooter>
					{_.isEmpty(buttons) ?
						<Button className="btn-flat btn-default" data-dismiss="modal" onClick={() => this.hideModal()}>Ok</Button> :
						_.map(buttons, button =>
							<Button key={button.title} className={button.className || 'btn-flat btn-default'} data-dismiss="modal" onClick={() => this.hideModal(button.callback)}>
								{button.title}
							</Button>
						)
					}
				</ModalFooter>
			</Modal>
		);
	}

	render() {
		const { didAutoLogin, showGDPRBox, googleAdsUserData, dv360UserData, loadingUserData, oauthRequired, showContactUs, exportsStatus } = this.state;
		const globalOAuthData = { googleAdsUserData, dv360UserData, loadingUserData, oauthRequired, fetchUserData: this.fetchUserData };
		const favicon = WhiteLabelController.siteAssets.favicon || require('../src/favicon.png');
		const { canAccessReportingOpportunities } = UserManager.user;

		if(!didAutoLogin) {
			return null;
		}

		// Disabling code splitting in development mode as it doesn't work properly
		let Login, BrandRegister, PasswordRestore, ChannelDetails, VideoDetails, ComscoreEditor, ScheduledExportsManager, PrecisionTargeting, CustomTagger,
			Build, VettingTool, ResearchParent, ReportingList, ReportingDetailsView, ReportingAccountSelector, BlocklistManager, UserPermissionsLog,
			AdminUserPermissions, DataTracker, UserSettings, AuditQueue, UsersList, UserAnalytics, TagsEditor, DomainManager, Inspect, InspectDetails,
			PacingReport, Forecasting, AccessDeniedPage, NotFound, WelcomePage, ErrorPage, HomePage, MyCustomData, ExportsManager, UmbrellaTopics,
			NotificationsManager, CIDManager, BuildInsights, CPRScript;
		if (process.env.NODE_ENV === 'development') {
			Login = {component: require('./components/auth/Login').default};
			BrandRegister = {component: require('./components/auth/BrandRegister').default};
			PasswordRestore = {component: require('./components/auth/PasswordRestore').default};

			ResearchParent = {component: require('./components/research/ResearchParent').default};
			ChannelDetails = {component: require('./components/channels/ChannelDetails').default};
			VideoDetails = {component: require('./components/videos/VideoDetails').default};

			Build = {component: require('./components/segments/Build').default};
			VettingTool = {component: require('./components/vetting_tool/VettingTool').default};

			// Review = {component: require('./components/review/Review.js').default};
			// ReviewAccountPage = {component: require('./components/review/ReviewAccountPage.js').default};
			PrecisionTargeting = {component: require('./components/precision_targeting/PrecisionTargetingList.js').default};

			Inspect = {component: require('./components/inspect/Inspect.js').default}
			InspectDetails = {component: require('./components/inspect/InspectDetails.js').default}

			UserSettings = {component: require('./components/auth/UserSettings').default};

			UsersList = {component: require('./components/admin/UsersList').default};
			UserPermissionsLog = {component: require('./components/admin/UserPermissionsLog').default};
			UserAnalytics = {component: require('./components/admin/UserAnalytics').default};
			TagsEditor = {component: require('./components/tags_editor/TagsEditor').default};
			NotificationsManager = {component: require('./components/notifications_manager/NotificationsManager').default};
			DomainManager = {component: require('./components/domain_manager/DomainManager').default};
			AdminUserPermissions = {component: require('./components/admin/AdminUserPermissions').default};
			BlocklistManager = {component: require('./components/admin/BlocklistManager').default};
			DataTracker = {component: require('./components/data_tracker/DataTracker').default};
			ComscoreEditor = {component: require('./components/comscore_editor/ComscoreEditor.js').default};
			UmbrellaTopics = {component: require('./components/admin/UmbrellaTopics.js').default};
			CIDManager = {component: require('./components/admin/CIDManager.js').default};
			CPRScript = {component: require('./components/admin/CPRScript.js').default};

			AuditQueue = {component: require('./components/audit_queue/AuditQueue').default};
			PacingReport = {component: require('./components/pacing_report/PacingReport').default};
			Forecasting = {component: require('./components/forecasting/Forecasting').default};
			MyCustomData = {component: require('./components/my_custom_data/MyCustomData').default};
			CustomTagger = {component: require('./components/tagger/CustomTagger').default};

			AccessDeniedPage = {component: require('./components/AccessDeniedPage').default};
			NotFound = {component: require('./components/NotFound').default};
			WelcomePage = {component: require('./components/WelcomePage').default};
			ErrorPage = {component: require('./components/ErrorPage').default};
			HomePage = {component: require('./components/home/HomePage').default};
			ExportsManager = {component: require('./components/exports_manager/ExportsManager').default};

			ReportingList = {component: require('./components/reporting/ReportingList').default};
			ReportingDetailsView = {component: require('./components/reporting/ReportingDetailsView.js').default};
			ReportingAccountSelector = {component: require('./components/reporting/ReportingAccountSelector').default};
			ScheduledExportsManager = {component: require('./components/reporting/ScheduledExportsManager.js').default};

			// ALPHA VERSION
			BuildInsights = {component: require('./components/explore/Explore.js').default};
		} else {
			Login = {getComponent: () => import('./components/auth/Login')};
			BrandRegister = {getComponent: () => import('./components/auth/BrandRegister')};
			PasswordRestore = {getComponent: () => import('./components/auth/PasswordRestore')};

			ResearchParent = {getComponent: () => import('./components/research/ResearchParent')};
			ChannelDetails = {getComponent: () => import('./components/channels/ChannelDetails')};
			VideoDetails = {getComponent: () => import('./components/videos/VideoDetails')};

			Build = {getComponent: () => import('./components/segments/Build')};
			VettingTool = {getComponent: () => import('./components/vetting_tool/VettingTool')};

			// Review = {getComponent: () => import('./components/review/Review')};
			// ReviewAccountPage = {getComponent: () => import('./components/review/ReviewAccountPage')};
			PrecisionTargeting = {getComponent: () => import('./components/precision_targeting/PrecisionTargetingList')};

			Inspect = {getComponent: () => import('./components/inspect/Inspect')}
			InspectDetails = {getComponent: () => import('./components/inspect/InspectDetails')}

			UserSettings = {getComponent: () => import('./components/auth/UserSettings')};

			UsersList = {getComponent: () => import('./components/admin/UsersList')};
			UserPermissionsLog = {getComponent: () => import('./components/admin/UserPermissionsLog')}
			UserAnalytics = {getComponent: () => import('./components/admin/UserAnalytics')};
			TagsEditor = {getComponent: () => import('./components/tags_editor/TagsEditor')};
			NotificationsManager = {getComponent: () => import('./components/notifications_manager/NotificationsManager')};
			DomainManager = {getComponent: () => import('./components/domain_manager/DomainManager')};
			AdminUserPermissions = {getComponent: () => import('./components/admin/AdminUserPermissions')};
			BlocklistManager = {getComponent: () => import('./components/admin/BlocklistManager')};
			DataTracker = {getComponent: () => import('./components/data_tracker/DataTracker')};
			ComscoreEditor = {getComponent: () => import('./components/comscore_editor/ComscoreEditor.js')};
			UmbrellaTopics = {getComponent: () => import('./components/admin/UmbrellaTopics.js')};
			CIDManager = {getComponent: () => import('./components/admin/CIDManager.js')};
			CPRScript = {getComponent: () => import('./components/admin/CPRScript.js')};

			AuditQueue = {getComponent: () => import('./components/audit_queue/AuditQueue')};
			PacingReport = {getComponent: () => import('./components/pacing_report/PacingReport')};
			Forecasting = {getComponent: () => import('./components/forecasting/Forecasting')};
			MyCustomData = {getComponent: () => import('./components/my_custom_data/MyCustomData')};
			CustomTagger = {getComponent: () => import('./components/tagger/CustomTagger')};
			// AdsAnalyzer = {getComponent: () => import('./components/ads_analyzer/AdsAnalyzer')};
			// CHFDashboard = {getComponent: () => import('./components/aw_accounts/CHFDashboard')};
			// CHFDashboardAccountDetails = {getComponent: () => import('./components/aw_accounts/CHFDashboardAccountDetails')};

			AccessDeniedPage = {getComponent: () => import('./components/AccessDeniedPage')};
			NotFound = {getComponent: () => import('./components/NotFound')};
			WelcomePage = {getComponent: () => import('./components/WelcomePage')};
			ErrorPage = {getComponent: () => import('./components/ErrorPage')};
			HomePage = {getComponent: () => import('./components/home/HomePage')};
			ExportsManager = {getComponent: () => import('./components/exports_manager/ExportsManager')};

			ReportingList = {getComponent: () => import('./components/reporting/ReportingList')};
			ReportingDetailsView = {getComponent: () => import('./components/reporting/ReportingDetailsView.js')};
			ReportingAccountSelector = {getComponent: () => import('./components/reporting/ReportingAccountSelector')};
			ScheduledExportsManager = {getComponent: () => import('./components/reporting/ScheduledExportsManager.js')};

			// ALPHA VERSION
			BuildInsights = {getComponent: () => import('./components/explore/Explore.js')};
		}

		const renderSideMenuRoutes = () => {
			return <SideMainMenu exportsStatus={exportsStatus} onFetchExportsStatus={this.onFetchExportsStatus}>
				<Switch>

					<AutoRedirectRoute exact path="/insights/channels" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission()} {...ResearchParent}/>
					<AutoRedirectRoute exact path="/insights/channels/:channelID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission('insights_single')} {...ChannelDetails}/>
					<AutoRedirectRoute exact path="/insights/videos" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission()} {...ResearchParent}/>
					<AutoRedirectRoute exact path="/insights/videos/:videoID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission('insights_single')} {...VideoDetails}/>
					<AutoRedirectRoute exact path="/insights/channels/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchVettingToolPermission()} {...VettingTool}/>
					<AutoRedirectRoute exact path="/insights/videos/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchVettingToolPermission()} {...VettingTool}/>
					<Redirect from="/insights" to="/insights/channels"/>

					<AutoRedirectRoute exact path="/build" extraProps={{globalOAuthData}} checkRedirect={() => this.checkBuildPermission()} {...Build}/>
					<AutoRedirectRoute exact path="/build/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkCTLVettingToolPermission()} {...VettingTool}/>

					<AutoRedirectRoute exact path="/report_plus/accounts" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('ac_reporting')} {...ReportingList}/>
					<AutoRedirectRoute exact path="/report_plus/accounts/detail/:accountID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('ac_reporting')} {...ReportingDetailsView}/>
					<AutoRedirectRoute exact path="/report_plus/opportunities" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('op_reporting')} {...ReportingList}/>
					<AutoRedirectRoute exact path="/report_plus/opportunities/detail/:opportunityID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('op_reporting')} {...ReportingDetailsView}/>
					<AutoRedirectRoute exact path="/report_plus/accounts/accounts_selector" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions} {...ReportingAccountSelector}/>
					<AutoRedirectRoute exact path="/report_plus/accounts/scheduled_exports" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions('exports')} {...ScheduledExportsManager}/>
					<AutoRedirectRoute exact path="/report_plus/opportunities/scheduled_exports" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions('exports_opportunity')} {...ScheduledExportsManager}/>
					<Redirect from="/report_plus" to={canAccessReportingOpportunities ? '/report_plus/opportunities' : '/report_plus/accounts'}/>

					<AutoRedirectRoute exact path="/forecasting" extraProps={{globalOAuthData}} checkRedirect={() => this.checkForecastingPermissions()} {...Forecasting}/>

					<AutoRedirectRoute exact path="/review" extraProps={{globalOAuthData}} checkRedirect={this.checkReviewPermissions} {...PrecisionTargeting}/>

					<AutoRedirectRoute exact path="/inspect" extraProps={{globalOAuthData}} checkRedirect={this.checkInspectPermissions} {...Inspect}/>
					<AutoRedirectRoute exact path="/inspect/:inspectID" extraProps={{globalOAuthData}} checkRedirect={this.checkInspectPermissions} {...InspectDetails}/>

					<AutoRedirectRoute exact path="/pacing_report" extraProps={{globalOAuthData}} checkRedirect={this.checkPacingReportPermissions} {...PacingReport}/>

					<AutoRedirectRoute exact path="/tools/custom_data" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('custom_data')} {...MyCustomData}/>
					<AutoRedirectRoute exact path="/tools/audit_queue" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('audit')} {...AuditQueue}/>
					<AutoRedirectRoute exact path="/tools/custom_tagger" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('custom_tagger')} {...CustomTagger}/>
					<Redirect from="/tools" to="/tools/audit_queue"/>

					<AutoRedirectRoute exact path="/admin/users" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...UsersList}/>
					<AutoRedirectRoute exact path="/admin/users/permissions_log" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...UserPermissionsLog}/>
					<AutoRedirectRoute exact path="/admin/users/permissions/:userID" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
					<AutoRedirectRoute exact path="/admin/roles/permissions/:userID" extraProps={{role:true,globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
					<AutoRedirectRoute exact path="/admin/roles/permissions/" extraProps={{role:true,globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
					<AutoRedirectRoute exact path="/admin/user_analytics" extraProps={{globalOAuthData}} checkRedirect={this.checkUserAnalyticsPermissions} {...UserAnalytics}/>
					<AutoRedirectRoute exact path="/admin/keywords_editor" extraProps={{globalOAuthData}} checkRedirect={this.checkBSTEPermissions} {...TagsEditor}/>
					<AutoRedirectRoute exact path="/admin/blocklist_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkBlocklistPermissions} {...BlocklistManager}/>
					<AutoRedirectRoute exact path="/admin/domain_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkDomainManagerPermissions} {...DomainManager}/>
					<AutoRedirectRoute exact path="/admin/data_tracker" extraProps={{globalOAuthData}} checkRedirect={this.checkDataTrackerPermissions} {...DataTracker}/>
					<AutoRedirectRoute exact path="/admin/comscore_editor" extraProps={{globalOAuthData}} checkRedirect={this.checkComscoreEditorPermissions} {...ComscoreEditor}/>
					<AutoRedirectRoute exact path="/admin/umbrella_topics" extraProps={{globalOAuthData}} checkRedirect={this.checkUmbrellaTopicsPermissions} {...UmbrellaTopics}/>
					<AutoRedirectRoute exact path="/admin/notifications_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkNotificationsManagerPermissions} {...NotificationsManager}/>
					<AutoRedirectRoute exact path="/admin/cid_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkCIDManagerPermissions} {...CIDManager}/>
					<AutoRedirectRoute exact path="/admin/cpr_script" extraProps={{globalOAuthData}} checkRedirect={this.checkCPRScriptPermissions} {...CPRScript}/>
					<Redirect from="/admin" to="/admin/users" />

					<AutoRedirectRoute exact path="/my_profile" extraProps={{globalOAuthData}} {...UserSettings}/>

					<AutoRedirectRoute exact path="/access_denied" {...AccessDeniedPage}/>
					<AutoRedirectRoute exact path="/home" {...HomePage}/>
					<AutoRedirectRoute exact path="/exports_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkExportsManagerPermissions} {...ExportsManager}/>

					{/* ALPHA VERSION */}
					<AutoRedirectRoute exact path="/explore" extraProps={{globalOAuthData}} {...BuildInsights}/>

					<AutoRedirectRoute {...NotFound}/>
				</Switch>
				{!document.cookie.includes(`${UserManager.user.email}=phone_popup`) && !UserManager.user.phoneNumberVerified && <NumberInputModal showOnLoad={true}/>}
				{UserManager.user.hasAcceptedGDPR == null && showGDPRBox ? <GDPRCookiesBox onGDPRCookiesAction={this.onGDPRCookiesAction} /> : null}
			</SideMainMenu>;
		};

		const renderMainMenuRoutes = () => {
			return <MainMenu exportsStatus={exportsStatus} onFetchExportsStatus={this.onFetchExportsStatus} globalOAuthData={globalOAuthData}>
				<Switch>

				<AutoRedirectRoute exact path="/insights/channels" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission()} {...ResearchParent}/>
				<AutoRedirectRoute exact path="/insights/channels/:channelID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission('insights_single')} {...ChannelDetails}/>
				<AutoRedirectRoute exact path="/insights/videos" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission()} {...ResearchParent}/>
				<AutoRedirectRoute exact path="/insights/videos/:videoID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchPermission('insights_single')} {...VideoDetails}/>
				<AutoRedirectRoute exact path="/insights/channels/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchVettingToolPermission()} {...VettingTool}/>
				<AutoRedirectRoute exact path="/insights/videos/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkResearchVettingToolPermission()} {...VettingTool}/>
				<Redirect from="/insights" to="/insights/channels"/>

				<AutoRedirectRoute exact path="/build" extraProps={{globalOAuthData}} checkRedirect={() => this.checkBuildPermission()} {...Build}/>
				<AutoRedirectRoute exact path="/build/vetting_tool/:vettedID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkCTLVettingToolPermission()} {...VettingTool}/>

				<AutoRedirectRoute exact path="/report_plus/accounts" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('ac_reporting')} {...ReportingList}/>
				<AutoRedirectRoute exact path="/report_plus/accounts/detail/:accountID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('ac_reporting')} {...ReportingDetailsView}/>
				<AutoRedirectRoute exact path="/report_plus/opportunities" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('op_reporting')} {...ReportingList}/>
				<AutoRedirectRoute exact path="/report_plus/opportunities/detail/:opportunityID" extraProps={{globalOAuthData}} checkRedirect={() => this.checkReportingPermissions('op_reporting')} {...ReportingDetailsView}/>
				<AutoRedirectRoute exact path="/report_plus/accounts/accounts_selector" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions} {...ReportingAccountSelector}/>
				<AutoRedirectRoute exact path="/report_plus/accounts/scheduled_exports" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions('exports')} {...ScheduledExportsManager}/>
				<AutoRedirectRoute exact path="/report_plus/opportunities/scheduled_exports" extraProps={{globalOAuthData}} checkRedirect={this.checkReportingPermissions('exports_opportunity')} {...ScheduledExportsManager}/>
				<Redirect from="/report_plus" to={canAccessReportingOpportunities ? '/report_plus/opportunities' : '/report_plus/accounts'}/>

				<AutoRedirectRoute exact path="/forecasting" extraProps={{globalOAuthData}} checkRedirect={() => this.checkForecastingPermissions()} {...Forecasting}/>

				<AutoRedirectRoute exact path="/review" extraProps={{globalOAuthData}} checkRedirect={this.checkReviewPermissions} {...PrecisionTargeting}/>

				<AutoRedirectRoute exact path="/inspect" extraProps={{globalOAuthData}} checkRedirect={this.checkInspectPermissions} {...Inspect}/>
				<AutoRedirectRoute exact path="/inspect/:inspectID" extraProps={{globalOAuthData}} checkRedirect={this.checkInspectPermissions} {...InspectDetails}/>

				<AutoRedirectRoute exact path="/tools/pacing_report" extraProps={{globalOAuthData}} checkRedirect={this.checkPacingReportPermissions} {...PacingReport}/>

				<AutoRedirectRoute exact path="/tools/custom_data" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('custom_data')} {...MyCustomData}/>
				<AutoRedirectRoute exact path="/tools/audit_queue" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('audit')} {...AuditQueue}/>
				<AutoRedirectRoute exact path="/tools/custom_tagger" extraProps={{globalOAuthData}} checkRedirect={this.checkToolsPermissions('custom_tagger')} {...CustomTagger}/>
				<Redirect from="/tools" to="/tools/audit_queue"/>

				<AutoRedirectRoute exact path="/admin/users" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...UsersList}/>
				<AutoRedirectRoute exact path="/admin/users/permissions_log" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...UserPermissionsLog}/>
				<AutoRedirectRoute exact path="/admin/users/permissions/:userID" extraProps={{globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
				<AutoRedirectRoute exact path="/admin/roles/permissions/:userID" extraProps={{role:true,globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
				<AutoRedirectRoute exact path="/admin/roles/permissions/" extraProps={{role:true,globalOAuthData}} checkRedirect={this.checkUserManagerPermissions} {...AdminUserPermissions}/>
				<AutoRedirectRoute exact path="/admin/user_analytics" extraProps={{globalOAuthData}} checkRedirect={this.checkUserAnalyticsPermissions} {...UserAnalytics}/>
				<AutoRedirectRoute exact path="/admin/keywords_editor" extraProps={{globalOAuthData}} checkRedirect={this.checkBSTEPermissions} {...TagsEditor}/>
				<AutoRedirectRoute exact path="/admin/blocklist_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkBlocklistPermissions} {...BlocklistManager}/>
				<AutoRedirectRoute exact path="/admin/domain_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkDomainManagerPermissions} {...DomainManager}/>
				<AutoRedirectRoute exact path="/admin/data_tracker" extraProps={{globalOAuthData}} checkRedirect={this.checkDataTrackerPermissions} {...DataTracker}/>
				<AutoRedirectRoute exact path="/admin/comscore_editor" extraProps={{globalOAuthData}} checkRedirect={this.checkComscoreEditorPermissions} {...ComscoreEditor}/>
				<AutoRedirectRoute exact path="/admin/umbrella_topics" extraProps={{globalOAuthData}} checkRedirect={this.checkUmbrellaTopicsPermissions} {...UmbrellaTopics}/>
				<AutoRedirectRoute exact path="/admin/notifications_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkNotificationsManagerPermissions} {...NotificationsManager}/>
				<AutoRedirectRoute exact path="/admin/cid_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkCIDManagerPermissions} {...CIDManager}/>
				<AutoRedirectRoute exact path="/admin/cpr_script" extraProps={{globalOAuthData}} checkRedirect={this.checkCPRScriptPermissions} {...CPRScript}/>
				<Redirect from="/admin" to="/admin/users" />

				<AutoRedirectRoute exact path="/my_profile" extraProps={{globalOAuthData}} {...UserSettings}/>

				<AutoRedirectRoute exact path="/access_denied" {...AccessDeniedPage}/>
				<AutoRedirectRoute exact path="/home" {...HomePage}/>
				<AutoRedirectRoute exact path="/exports_manager" extraProps={{globalOAuthData}} checkRedirect={this.checkExportsManagerPermissions} {...ExportsManager}/>

				{/* ALPHA VERSION */}
				<AutoRedirectRoute exact path="/explore" extraProps={{globalOAuthData}} {...BuildInsights}/>

				<AutoRedirectRoute {...NotFound}/>
				</Switch>
				{!document.cookie.includes(`${UserManager.user.email}=phone_popup`) && !UserManager.user.phoneNumberVerified && <NumberInputModal showOnLoad={true}/>}
				{UserManager.user.hasAcceptedGDPR == null && showGDPRBox ? <GDPRCookiesBox onGDPRCookiesAction={this.onGDPRCookiesAction} /> : null}
			</MainMenu>;
		};

		return (
			<Router history={history}>
				<div>
					<Favicon url={favicon} />
					<Switch>
						<AutoRedirectRoute exact path="/" {...Login}/>
						<AutoRedirectRoute exact path="/login" {...Login}/>
						<AutoRedirectRoute exact path="/brand_register" {...BrandRegister}/>
						<AutoRedirectRoute exact path="/password_reset" {...PasswordRestore}/>
						<AutoRedirectRoute exact path="/welcome" {...WelcomePage}/>
						<AutoRedirectRoute exact path="/maintenance" {...ErrorPage}/>
						<Route render={() => {
							if(UserManager.isAuthorized && UserManager.user.isActive) {
								if (UserManager.user.canAccessNewNavigation) {
									return renderSideMenuRoutes();
								}
								return renderMainMenuRoutes();
							}
							return <Redirect to="/login"/>
						}}/>

					</Switch>
					{this.renderModal()}
					{showContactUs && UserManager.user.isActive && <ContactUsModal id="app-contact-us-modal"/>}
					<ReactTooltip id="default-tooltip"/>
					<ReactTooltip id="html-tooltip" html={true}/>
				</div>
			</Router>
		);
	}

}

export const RoutesClass = Routes;
export default hot(module)(Routes);
