import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import PopoverHelper from '../PopoverHelper';
import GenericSelector from './GenericSelector';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IconContext } from 'react-icons';


class DropdownSelector extends GenericSelector {

    static defaultProps = {
        noSelectedClass: false,
        multiselect: false,
        showChevron: false,
        allowSearch: true
    }

    constructor(props) {
        super(props);
        this.state = {
            popoverOpened: false,
            search: ''
        }
    }

    render() {
        const { popoverOpened, search } = this.state;
        const { options, selections, id, className, idKey, titleKey, noSelectedClass, multiselect, showChevron, allowSearch, children, renderIcon, disableCheckbox, isHeader, disablePopup } = this.props;
        const optionsToShow = _.isEmpty(search) ?
            options :
            _.filter(options, opt => opt[titleKey]?.toLowerCase().includes(search.toLowerCase()));
        const allSelected = selections?.length == options?.length;
        return <div key={`selector_${id}`} className={cx(isHeader?className:'dropdown-selector', className)}>
            <div className={cx('popover-opener',{'selected':!_.isEmpty(selections)&&!noSelectedClass})} onClick={() => (disablePopup ? this.onPopoverChange(false) : this.onPopoverChange(true))}>
                {children ? children : this.renderTitle()}
                {!showChevron || disablePopup ? null : popoverOpened ?
                    <span className='ml-5'><IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}><FaChevronUp/></IconContext.Provider></span> :
                    <span className='ml-5'><IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}><FaChevronDown/></IconContext.Provider></span>}
            </div>
            <PopoverHelper id={`popover_${id}`} show={popoverOpened} closePopover={() => this.onPopoverChange(false)}>
                {allowSearch && <div className='popover-controller'>
                    <div className={cx('p-relative width-100', {'mr-10':multiselect})}>
                        <input type='text' placeholder='Search...' value={search || ''} onChange={e => this.onSearch(e.currentTarget.value)} />
                        <span className='material-symbols-outlined' onClick={() => this.onSearch('')}>close</span>
                    </div>
                    {multiselect && <span className='select-all-btn' onClick={this.onSelectAll}>{allSelected ? 'Unselect All' : 'Select All'}</span>}
                </div>}
                <div className='dropdown-items'>
					{_.map(optionsToShow, item => {
						const isSelected = this.onCheckIfSelected(item);
						const enabled = 'enabled' in item ? item.enabled : true;
						return  enabled && <div key={`dropdown-${id}_${item[idKey]}`} className={cx('dp-item', { 'inactive': !isSelected })} onClick={() => this.onChange(item)}>
							{!disableCheckbox && this.renderCheckbox(isSelected)}
							{renderIcon && item?.icon}
							{item[titleKey]}
						</div>;
					})}
				</div>
            </PopoverHelper>
        </div>;
    }
}

export default DropdownSelector;
